.warning-msg {
    color: #9F6000 !important;
    background-color: #FEEFB3 !important;
  }
.warning-msg-hover:hover {
    background-color: #9F6000 !important;
}
.warning-msg-text {
    background-color: #9F6000 !important;
  }
.warning-msg-border{
    border:1px solid #9F6000 !important;
}