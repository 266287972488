.addArticle {
  height: 100px;
  overflow: hidden;
  transition: height 0.8s ease-in-out;

  &.label_0 {
    &:hover {
      height: 290px;
    }
  }

  &.label_1 {
    &:hover {
      height: 320px;
    }
  }

  &.label_2 {
    &:hover {
      height: 350px;
    }
  }

  &.label_3 {
    &:hover {
      height: 380px;
    }
  }

  &.label_4 {
    &:hover {
      height: 410px;
    }
  }

  &:focus {
    height: 410px;
  }
}

.addWishListArticle {
  height: 100px;
  overflow: hidden;
  transition: height 0.8s ease-in-out;

  &.label_0 {
    &:hover {
      height: 230px;
    }
  }

  &.label_1 {
    &:hover {
      height: 260px;
    }
  }

  &.label_2 {
    &:hover {
      height: 290px;
    }
  }
  &.label_3 {
    &:hover {
      height:320px;
    }
  }

  &:focus {
    height: 220px;
  }
}
