.plusIcon {
  width: 25px;
  height: 25px;
  border: 0;
  font-size: 1.5em;
  position: relative;
  background: transparent;

  & span {
    position: absolute;
    transition: 300ms;
    background: black;
    border-radius: 2px;

    /* Create the "+" shape by positioning the spans absolutely */
    &:first-child{
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%;
    }
    &:last-child{
      left: 25%;
      right: 25%;
      height: 10%;
      top: 45%;
    }
  }

  /* Morph the shape when the .plusIcon is hovered over */
  &:focus span {

  }
}


