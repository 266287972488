form label {
  display: none;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: all 500ms ease ;
  margin-left: 2px;
  position:relative;
}
.right {
  top: -5px;
  transform: rotate(-45deg);
}

.down {
  top: -6px;
  margin-left: 4px;
  transform: rotate(45deg);
}

.left {
  top: -5px;
  margin-right: 4px;
  transform: rotate(135deg);
}