input#responsive-nav,
label.responsive-nav-label {
  display: none;
}
@media only screen and (max-width: 1023px) {
  label.responsive-nav-label span {
    margin-right: 10px;
  }
  label.responsive-nav-label {
    position: relative;
    display: block;
    padding: 20px;
    background: #222;
    cursor: pointer;
    color: #fff;
  }
  nav {
    position: absolute;
    top: -9999px;
    padding: 10px;
  }
  input#responsive-nav[type="checkbox"]:checked ~ nav {
    position: relative;
    top: 0;
  }
  nav a:after {
    display: none;
  }
  nav li {
    float: none !important;
    width: 100% !important;
    border-right: none !important;
  }
  nav li a {
    margin-bottom: 10px !important;
    padding: 10px 20px !important;
    background: #4a4a4a;
  }
  nav ul li:hover {
    background: none;
  }
  nav ul li a:hover {
    background: #4096ee;
  }
  nav ul ul {
    position: relative !important;
    width: 100%;
    left: 0 !important;
    top: 0 !important;
    background: none !important;
  }
  nav ul ul li {
    padding-left: 20px;
  }
  nav ul li a:active {
    background: #4096ee;
  }
}
nav {
  background-color: #333;
  box-sizing: border-box;
  float: left;
  width: 100%;
  height: auto;
  font-weight: bold;
  margin-bottom: 10px;
}
nav img {
  height: 30px;
  vertical-align: middle;
}
nav ul {
  margin: 0;
  padding: 0;
}
nav a {
  display: block;
  color: white;
  text-decoration: none;
}
nav ul li {
  position: relative;
  float: left;
  list-style: none;
  border-right: 1px solid #bbb;
}
nav ul li a:hover {
  background: #4096ee;
}
nav ul li a:active {
  background: #4096ee;
}
nav ul li:last-child {
  border-right: none;
  float: right;
}
nav ul li > a:after {
  content: " \25BE";
}
nav ul li > a:only-child:after {
  content: "";
}
nav ul li a {
  padding: 15px;
}
nav ul ul {
  top: -9999px;
  left: -9999px;
  position: absolute;
  background-color: #333;
  z-index: 100;
}
nav ul ul li {
  float: none;
  width: 200px;
  border-right: none;
}
nav ul li:hover:last-child ul {
  left: auto;
  right: 0;
}
nav ul ul li a {
  padding: 10px 20px;
}
nav ul li:hover > ul {
  top: 100%;
  left: 0;
}
nav ul ul li:hover > ul {
  top: 0;
  left: 150px;
}
