.margin-8 {
  margin: 8px !important;
}

.margin-4 {
  margin: 4px !important;
}

.margin-left-6 {
  margin-left: 6px !important;
}

.stretch {
  margin-left: -16px;
  margin-right: -16px;
}

.btn {
  cursor: pointer;
  vertical-align: middle;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.padding {
  &-0 {
    padding: 0 !important;
  }

  &-8 {
    padding: 8px !important;;
  }
}

@media (max-width: 1279px) {
  .display-xlarge {
    display: none !important
  }
}

@media (min-width: 1280px) {
  .hide-xlarge {
    display: none !important
  }
}

.dynamic-row {
  display: inline-flex !important;
  flex-wrap: wrap !important;
  width: 100%;

  &-bottom > * {
    align-self: end !important;
  }
}

.switch {
  position: relative;;
  display: inline-block;
  width: 50px;
  height: 27px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

input:checked + .slider {
  background-color: #2196F3;
  &:before {
    -webkit-transform: translateX(23px);
    transform: translateX(23px);
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
